
import { defineComponent, ref, reactive, nextTick } from "vue";

import liveState from "@/api/liveState";

export default defineComponent({
  name: "CourseWX",
  setup() {

    liveState()
          .then((res: any) => {
            console.log('2222');
          })
          .catch((e) => {
            console.log(e, "error, -----------------------");
      });
    return {
    };
  },
});
